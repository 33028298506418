import './App.css';
import React, { Component } from 'react';
import LandingPage from './pages/Landing/landingPage';
import Service from './components/Services/Service';
import Careers from './pages/Careers/Careers.jsx';
import {BrowserRouter as Router,Route,Switch} from 'react-router-dom';
import RecruitmentClients from './pages/Client/RecruitmentClients';
import TechnologyClients from './pages/Client/TechnologyClients';
import Homepage from './pages/Technology/techhomepage/homepage';
import Contact from './pages/Contact/Contact';
import Aboutus from './pages/AboutUs/AboutUs';
import Header from './components/Header/header';
import Recruitment from './pages/Recruitment/Recruitment';
import TechnologyService from './components/Services/TechnologyService';
import TiltCard from './components/TiltCard/TiltCard';
import ExpandableCard from './components/CardComponent/ExpandableCard';
import RecruitmentService from './components/Services/RecruitmentService';
import AboutUsMain from './pages/AboutUs/AboutUsMain';

function App()   {

  return (    
    <div>
      <Router>
          <Switch>
            <Route exact path='/' exact component={LandingPage}/>
            <Route path='/services' component={Service}/>
            <Route path='/aboutus' component={AboutUsMain}/> 
            <Route path='/recruitment' component={Recruitment}/> 
            <Route path='/careers' component={Careers}/> 
            <Route path='/technology-clients' component={TechnologyClients}/>
            <Route path='/recruitment-clients' component={RecruitmentClients}/>
            <Route path='/technology-services' component={TechnologyService}/>
            <Route path='/recruitment-services' component={RecruitmentService}/>
            <Route path='/technology' component={Homepage}/>
            <Route path='/contact' component={Contact}/>
            <Route path='/linkedin' component={() => { 
                  window.location.href = 'https://www.linkedin.com/company/bitbybit-solutions/'; 
                  return null;}}>
            </Route>
            <Route path='/instagram' component={() => { 
                  window.location.href = 'https://www.instagram.com/bitbyitsolutions/'; 
                  return null;}}>
            </Route>
            <Route path='/facebook' component={() => { 
                  window.location.href = 'https://www.facebook.com/Bitbybit-Solutions-110527858156230'; 
                  return null;}}>
            </Route>
            <Route path='/twitter' component={() => { 
                  window.location.href = 'https://twitter.com/BitByBitSoluti1'; 
                  return null;}}>
            </Route>

          </Switch>
      </Router>
    </div>    
  );
}

export default App;
