import React from 'react';
import './landingPage.scss';
import b3stamp from '../../assets/images/b3stamp.png'
import { Button } from '@material-ui/core';
import Header from '../../components/Header/header'
import { useHistory } from 'react-router-dom';




const LandingPage = (props) => {
    const history = useHistory();
    function goToTechnology (){
        history.push("/technology");
    }
    function goToRecruitment (){
        history.push("/recruitment");
    }
    return (
    <div className="landingMain">
        <Header></Header>
        <div className="landingcontainer">
            <div className="technology">
                <div className="techchild">
                    <div className="techcontent">
                        <h1>TECHNOLOGY</h1>
                        <Button variant="outlined" className="seeMore" onClick={goToTechnology}>SEE MORE</Button>
                    </div>
                </div>
            </div>
            <div className="centerLogo" >
                <img className="b3stamp" src={b3stamp} alt="b3stamp" height="170" width="170"/>
            </div>
            <div className="recruitment">
                <div className="recruitmentchild">
                    <div className="recruitmentcontent">
                        <h1>RECRUITMENT</h1>
                        <Button variant="outlined" className="seeMore" onClick={goToRecruitment}>SEE MORE</Button>
                    </div>
                </div>
            </div>
        </div>

    </div>
    );
}

export default LandingPage;