export const techitems = [

  {
    title: 'Home',
    path: '/technology',
    cName: 'dropdown-link'
  },
    {
      title: 'Services',
      path: '/technology-services',
      cName: 'dropdown-link'
    },
    {
      title: 'Clients',
      path: '/technology-clients',
      cName: 'dropdown-link'
    },
    
  ];

  export const recruitmentitems=[
    {
      title:'Home',
      path:'/recruitment',
      cName:'dropdown-link'
    },
    {
      title:'Services',
      path:'/recruitment-services',
      cName:'dropdown-link'
    },
    {
      title:'Clients',
      path:'/recruitment-clients',
      cName:'dropdown-link'
    }
  ];