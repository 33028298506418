import React from 'react'
import GeometricCircle from './geometry'

function Canvas() {
    return (
        <div id="canvas" style={{position:"absolute", backgroundColor:"#0060B5"}}>
            <GeometricCircle/>
        </div>
    )
}

export default Canvas
