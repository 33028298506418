import React from 'react';
import "./Cards.scss";


function Cards() {
    return (
        <div>
            <div class="container">
                <ul class="careercards">
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"17px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/1496/1496034.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="careercard__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Design</h2>
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Neque ipsum veritatis id quia cupiditate sed architecto aliquam nostrum unde nam minima voluptas dicta, beatae sint reprehenderit sit ducimus officiis ratione?</p>
                        </div>
                    </li>
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"16px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/1336/1336494.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="card__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Code</h2>
                            <p>Dignissimos ipsam culpa vitae vel hic harum omnis doloremque debitis laudantium neque quos iure voluptates quidem ratione esse modi, rerum illum facere eius accusantium dolorem incidunt beatae officiis voluptatem? Nostrum.</p>
                        </div>
                    </li>
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"16px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/478/478543.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="careercard__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Launch</h2>
                            <p>Asperiores, harum dignissimos at neque quaerat, excepturi, ipsa in consectetur blanditiis tempore error! Libero fuga possimus qui saepe temporibus praesentium, ut ratione facere rem distinctio, beatae omnis officiis illum animi.</p>
                        </div>
                    </li>
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"16px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/1496/1496034.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="careercard__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Design</h2>
                            <p>Asperiores, harum dignissimos at neque quaerat, excepturi, ipsa in consectetur blanditiis tempore error! Libero fuga possimus qui saepe temporibus praesentium, ut ratione facere rem distinctio, beatae omnis officiis illum animi.</p>
                        </div>
                    </li>
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"16px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/478/478543.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="careercard__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Launch</h2>
                            <p>Asperiores, harum dignissimos at neque quaerat, excepturi, ipsa in consectetur blanditiis tempore error! Libero fuga possimus qui saepe temporibus praesentium, ut ratione facere rem distinctio, beatae omnis officiis illum animi.</p>
                        </div>
                    </li>
                    <li class="careercard careercards__item">
                        <div class="careercard__frame">
                        <p style={{fontSize:"16px"}}>
                                India | Mumbai
                            </p>
                            <div class="careercard__picture">
                                <img src="https://image.flaticon.com/icons/svg/478/478543.svg" alt="" width="120"></img>
                            </div>
                            <h2 class="careercard__title" style={{fontFamily:"cursive",fontSize:"18px"}}>
                            New Associate-Domestic Language</h2>
                        </div>
                        <div class="careercard__overlay"></div>
                        <div class="careercard__content">
                            <h2>Launch</h2>
                            <p>Asperiores, harum dignissimos at neque quaerat, excepturi, ipsa in consectetur blanditiis tempore error! Libero fuga possimus qui saepe temporibus praesentium, ut ratione facere rem distinctio, beatae omnis officiis illum animi.</p>
                        </div>
                    </li>

                </ul>
            </div>

        </div>
    )
}

export default Cards;